import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import strings from "store/Localization";
import "./index.css";

function TermsAndCondition(props: any) {
  const params = useParams();
  // eslint-disable-next-line
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {}
  );

  useEffect(() => {
    console.log(params?.lang);
    if (params?.lang) {
      strings.setLanguage(params?.lang);
      setState({});
      return;
    }
    strings.setLanguage("fr");
    setState({});
    // eslint-disable-next-line
  }, [params?.lang]);

  return (
   <div className="body"> 
      <Grid
        container
        spacing={4}
                style={{
          display: "flex",
          alignItems: "flex-start",
          padding: "10px",
          paddingLeft:'20px',
          paddingRight:'20px'

        }}
        
      >
        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <a href="https://www.v2internal.frankme.com" target="_blank">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            height={200}
            width={200}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 992.9 653.7"
            xmlSpace="preserve"
            style={{ fill: "#ee6931" }}
          >
            <title>Asset 1</title>
            <g>
              <g id="Layer_1-2">
                <polygon
                  className="st0"
                  points="49.3,338.7 171.1,306 169.6,277.1 17,318 30.4,574.7 61.2,566.5 56,468.7 158.3,441.3 156.9,414.2 
			54.6,441.6 		"
                />
                <polygon
                  className="st0"
                  points="957.5,66 924,75 814.6,248.4 807.4,106.6 776.6,114.8 789.9,371.2 820.7,363 816.6,284.5 
			853.1,227.8 942.2,330.4 976,321.4 871.2,203.9 		"
                />
                <path
                  className="st0"
                  d="M714.1,131.6l10.6,199.6L573.4,168.9l-24,6.4c0,0,7.6,142.8,7.6,146.1c0.3,22.2-5.1,44-15.7,63.5
			c-3.8,7-8.2,13.6-13.2,19.7c-2.9,3.6-6,7-9.3,10.2c-20.9,20.9-48.4,33.9-77.8,36.8c-11.9,1.2-23.9,0.8-35.8-1.3
			c-9.4-1.6-18.6-4.3-27.4-8c-22.6-9.5-42.1-25.2-56.2-45.3l0.5-0.2c8.6-4.8,16.1-11.2,22.3-18.8c12.7-15.6,20.4-34.6,22.2-54.5
			c1-9.9,0.1-20-2.4-29.6c-2.2-8.1-5.6-15.9-10.2-22.9c-4.4-6.8-9.9-12.8-16.4-17.6c-6.4-4.6-13.6-7.9-21.3-9.7
			c-8.2-1.8-16.7-1.5-24.7,0.8L192.4,271l13.4,256.7l30.8-8.3l-4.9-92.2l59.5-16l1.7-0.8c18.4,28.2,45,49.9,76.3,62.2
			c9,3.5,18.3,6.3,27.7,8.1c15.6,3,31.5,3.7,47.3,2c8.6-0.9,17.1-2.5,25.5-4.7c23.1-6.2,44.6-17.6,62.7-33.3
			c7.3-6.2,14-13.1,20.1-20.5c1.5-1.9,3-3.8,4.5-5.8c1.7-2.3,3.3-4.5,4.8-6.9c17-25.5,26.3-55.4,26.7-86c0.1-6.4-5.2-98-5.2-98
			l149.2,158.8l25.7-6.9l-13.3-256.4L714.1,131.6z M299,379.9l-19.5,5.2l-49.2,13.2l-5.5-106.7l66.7-17.9c4.4-1.2,9-1.2,13.4-0.1
			c4.4,1.2,8.5,3.3,12.1,6.1c3.9,3.1,7.3,6.9,9.9,11.1c3,4.6,5.2,9.6,6.6,14.9c2,7.5,2.6,15.4,1.8,23.2c-0.8,7.7-2.7,15.2-5.8,22.3
			c-2.9,6.8-7.1,12.9-12.4,18.2c-2.3,2.3-4.8,4.3-7.6,5.9C306.3,377.4,302.7,378.9,299,379.9"
                />
                <path
                  className="st0"
                  d="M399.6,429l8.6-31.8l40.5-156.8c13.5,33.9,53.3,120.1,64.4,153c3.3-3.3,6.4-6.7,9.3-10.2
			c5-6.2,9.4-12.8,13.2-19.7l-75.9-164.1l-26.4,7.1L372.2,421C381,424.7,390.2,427.4,399.6,429"
                />
              </g>
            </g>
          </svg>
          </a>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            
          
          }}
        >
          <p className="main_heading">{strings.terms_and_conditions}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            // justifyContent: "center",
            // alignItems: "flex-start",
            // width: "700px",
            flexDirection: "column",
            

            
             
          }}
        >
          <p className="content_heading">{strings.intoduction}</p>
          <p className="content">{strings.terms_and_conditions_para_1}</p>
          <p className="content">{strings.terms_and_conditions_para_2}</p>
          <p className="content">{strings.terms_and_conditions_para_3}</p>
          <p className="content">{strings.terms_and_conditions_para_4}</p>
          <p className="content">{strings.terms_and_conditions_para_5} <a href="mailto:info@frankme.com">info@frankme.com </a></p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            // justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Article_1}</p>
          <p className="content">{strings.Article_definition_para_1}</p>

          {/* <p className="content">{strings.def_point_14}</p> */}
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Account}</p>
          <p className="content">{strings.Account_des}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_sub_heading">
            {strings.Additional_Customer_Benefits}
          </p>
          <p className="content">{strings.Additional_Customer_Benefits_des}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            alignItems: "flex-start",

            flexDirection: "column",
          }}
        >
          <p className="content_sub_heading">{strings.Advertisement}</p>
          <p className="content">{strings.Advertisement_des}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Carrier}</p>
          <p className="content">{strings.Carrier_des}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Collection_point}</p>
          <p className="content">{strings.Collection_point_des}</p>
          <p className="content">{strings.Collection_point_des_2}</p>

        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Company}</p>
          <p className="content">{strings.Company_desc}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Contribution}</p>
          <p className="content">{strings.Contribution_des}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Cotransporter}</p>
          <p className="content">{strings.Cotransporter_des}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Customer}</p>
          <p className="content">{strings.Customer_des}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Drop_off_point}</p>
          <p className="content">{strings.Drop_off_point_des}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            alignItems: "flex-start",

            flexDirection: "column",
          }}
        >
          <p className="content_heading">{strings.Packages}</p>
          <p className="content">{strings.Packages_des_1}</p>
          <p className="content">{strings.Packages_des_2}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Pick_up_point}</p>
          <p className="content">{strings.Pick_up_point_des}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Platform}</p>
          <p className="content">{strings.Platform_des}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Professional_carrier}</p>
          <p className="content">{strings.Professional_carrier_des}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Profile}</p>
          <p className="content">{strings.Profile_des}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Recipient}</p>

          <p className="content">{strings.Recipient_des_1}</p>

          <p className="content">{strings.Recipient_des_2}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Service}</p>
          <p className="content">{strings.Service_des_1}</p>

          <p className="content">{strings.Service_des_2}</p>

          <p className="content">{strings.Service_des_3}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Storage}</p>
          <p className="content">{strings.Storage_des}</p>
          <p className="content">{strings.Storage_provider}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Supplier}</p>
          <p className="content">{strings.Supplier_des_1}</p>
          <p className="content">{strings.Supplier_des_2}</p>
          <p className="content">{strings.Supplier_des_3}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">
            {strings.Terms_and_Conditions_of_Sale}
          </p>
          <p className="content">{strings.Terms_and_Conditions_of_Sale_des}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.User}</p>
          <p className="content">{strings.User_des}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Artice_2}</p>
          <p className="content_sub_heading">{strings.Artice_2_1_head}</p>
          <p className="content">{strings.Artice_2_1_para_1}</p>
          <p className="content">{strings.Artice_2_1_para_2}</p>
          <p className="content">{strings.Artice_2_1_para_3}</p>
          <p className="content">{strings.Artice_2_1_para_4}</p>
          <p className="content">{strings.Artice_2_1_para_5}</p>
          <p className="content">{strings.Artice_2_1_para_6}</p>
          <p className="content">{strings.Artice_2_1_para_7}</p>
          <p className="content">{strings.Artice_2_1_para_8}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_sub_heading">{strings.Artice_2_2_head}</p>
          <p className="content">{strings.Artice_2_2_para_1}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_sub_heading">{strings.Artice_2_3_head}</p>
          <p className="content">{strings.Artice_2_3_para_1}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_sub_heading">{strings.Artice_2_4_head}</p>
          <p className="content">{strings.Artice_2_4_para_1}</p>
          <p className="content">{strings.Artice_2_4_para_2}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_sub_heading">{strings.Artice_2_5_head}</p>
          <p className="content">{strings.Artice_2_5_para_1}</p>
          <p className="content">{strings.Artice_2_5_para_2}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Artice_3}</p>
          <p className="content_sub_heading">{strings.Artice_3_1_head}</p>
          <p className="content">{strings.Artice_3_1_para_1}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_sub_heading">{strings.Artice_3_2_head}</p>
          <p className="content">{strings.Artice_3_2_para_1}</p>
          <p className="content">{strings.Artice_3_2_para_2}</p>
          <p className="content">{strings.Artice_3_2_para_3}</p>
          <p className="content">{strings.Artice_3_2_para_4}</p>
          <p className="content">{strings.Artice_3_2_para_5}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_sub_heading">{strings.Artice_3_3_head}</p>
          <p className="content">{strings.Artice_3_3_para_1}</p>
          <p className="content">{strings.Artice_3_3_para_2}</p>
          <p className="content">{strings.Artice_3_3_para_3}</p>
          <p className="content">{strings.Artice_3_3_para_4}</p>
          <p className="content">{strings.Artice_3_3_para_5}</p>
          <p className="content">{strings.Artice_3_3_para_6}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Artice_4}</p>
          <p className="content">{strings.Artice_4_para_1}</p>
          <p className="content">{strings.Artice_4_para_2}</p>

          <p className="content_sub_heading">{strings.Artice_4_1_head}</p>

          <p className="content">{strings.Artice_4_1_para_1}</p>
          <p className="content">{strings.Artice_4_1_para_2}</p>
          <p className="content">{strings.Artice_4_1_para_3}</p>
          <p className="content">{strings.Artice_4_1_para_4}</p>
          <p className="content">{strings.Artice_4_1_para_5}</p>

          <p className="content_sub_heading">{strings.Artice_4_2_head}</p>

          <p className="content">{strings.Artice_4_2_para_1}</p>
          <p className="content">{strings.Artice_4_2_para_2}</p>

          <p className="content_sub_heading">{strings.Artice_4_3_head}</p>

          <p className="content">{strings.Artice_4_3_para_1}</p>
          <p className="content">{strings.Artice_4_3_para_2}</p>
          <p className="content">{strings.Artice_4_3_para_3}</p>

          <p className="content_sub_heading">{strings.Artice_4_4_head}</p>

          <p className="content">{strings.Artice_4_4_para_1}</p>
          <p className="content">{strings.Artice_4_4_para_2}</p>
          <p className="content">{strings.Artice_4_4_para_3}</p>
          <p className="content">{strings.Artice_4_4_para_4}</p>

          <p className="content_sub_heading">{strings.Artice_4_5_head}</p>

          <p className="content">{strings.Artice_4_5_para_1}</p>
          <p className="content">{strings.Artice_4_5_para_2}</p>
          <p className="content">{strings.Artice_4_5_para_3}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Artice_5}</p>
          <p className="content_sub_heading">{strings.Artice_5_1_head}</p>
          <p className="content">{strings.Artice_5_1_para_1}</p>

          <p className="content_sub_heading">{strings.Artice_5_2_head}</p>
          <p className="content">{strings.Artice_5_2_para_1}</p>
          <p className="content">{strings.Artice_5_2_point_1}</p>
          <p className="content">{strings.Artice_5_2_point_2}</p>
          <p className="content">{strings.Artice_5_2_point_3}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Artice_6}</p>
          <p className="content_sub_heading">{strings.Artice_6_1_head}</p>
          <p className="content">{strings.Artice_6_1_para_1}</p>
          <p className="content">{strings.Artice_6_1_para_2}</p>
          <p className="content">{strings.Artice_6_1_para_3}</p>
          <p className="content">{strings.Artice_6_1_para_4}</p>
          <p className="content">{strings.Artice_6_1_para_5}</p>
          <p className="content">{strings.Artice_6_1_para_6}</p>

          <p className="content_sub_heading">{strings.Artice_6_2_head}</p>
          <p className="content">{strings.Artice_6_2_para_1}</p>
          <p className="content">{strings.Artice_6_2_para_2}</p>
          <p className="content">{strings.Artice_6_2_para_3}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Artice_7}</p>
          <p className="content_sub_heading">{strings.Artice_7_1_head}</p>
          <p className="content">{strings.Artice_7_1_para_1}</p>
          <p className="content">{strings.Artice_7_1_para_2}</p>
          <p className="content">{strings.Artice_7_1_para_3}</p>

          <p className="content_sub_heading">{strings.Artice_7_2_head}</p>
          <p className="content">{strings.Artice_7_2_para_1}</p>
          <p className="content">{strings.Artice_7_2_para_2}</p>
          <p className="content">{strings.Artice_6_2_para_3}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Artice_8}</p>
          <p className="content_sub_heading">{strings.Artice_8_1_head}</p>
          <p className="content">{strings.Artice_8_1_para_1}</p>
          <p className="content">{strings.Artice_8_1_para_2}</p>
          <p className="content">{strings.Artice_8_1_para_3}</p>
          <p className="content">{strings.Artice_8_1_para_4}</p>

          <p className="content_sub_heading">{strings.Artice_8_2_head}</p>
          <p className="content">{strings.Artice_8_2_para_1}</p>
          <p className="content">{strings.Artice_8_2_point_2}</p>
          <p className="content">{strings.Artice_8_2_point_3}</p>
          <p className="content">{strings.Artice_8_2_point_4}</p>
          <p className="content">{strings.Artice_8_2_point_5}</p>
          <p className="content">{strings.Artice_8_2_point_6}</p>
          <p className="content">{strings.Artice_8_2_point_7}</p>
          <p className="content">{strings.Artice_8_2_point_8}</p>
          <p className="content">{strings.Artice_8_2_point_9}</p>
          <p className="content">{strings.Artice_8_2_point_10}</p>
          <p className="content">{strings.Artice_8_2_point_11}</p>
          <p className="content">{strings.Artice_8_2_point_12}</p>
          <p className="content">{strings.Artice_8_2_point_13}</p>
          <p className="content">{strings.Artice_8_2_point_14}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Artice_9}</p>
          <p className="content">{strings.Artice_9_para_1}</p>
          <p className="content">{strings.Artice_9_point_2}</p>
          <p className="content">{strings.Artice_9_point_3}</p>
          <p className="content">{strings.Artice_9_point_4}</p>
          <p className="content">{strings.Artice_9_point_5}</p>

          <p className="content">{strings.Artice_9_point_6}</p>
          <p className="content">{strings.Artice_9_point_7}</p>
          <p className="content">{strings.Artice_9_para_3}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Artice_10}</p>
          <p className="content">{strings.Artice_10_para_1}</p>
          <p className="content">{strings.Artice_10_point_1}</p>
          <p className="content">{strings.Artice_10_point_2}</p>
          <p className="content">{strings.Artice_10_para_2}</p>
          <p className="content">{strings.Artice_10_para_3}</p>

          <p className="content">{strings.Artice_10_para_4}</p>
          <p className="content">{strings.Artice_10_para_5}</p>
          <p className="content">{strings.Artice_10_para_6}</p>
          <p className="content">{strings.Artice_10_para_7}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Artice_11}</p>
          <p className="content">{strings.Artice_11_para_1}</p>
          <p className="content">{strings.Artice_11_para_2}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Appendex_1}</p>
          <p className="content">{strings.Appendex_1_para_1}</p>
          <p className="content">{strings.Appendex_1_head_1_para_1}</p>
          <p className="content_sub_heading">{strings.Appendex_1_head_2}</p>
          <p className="content">{strings.Appendex_1_head_2_para_1}</p>
          <p className="content_sub_heading">{strings.Appendex_1_head_3}</p>
          <p className="content">{strings.Appendex_1_head_3_para_1}</p>
          <p className="content_sub_heading">{strings.Appendex_1_head_4}</p>
          <p className="content">{strings.Appendex_1_head_4_para_1}</p>
          <p className="content_sub_heading">{strings.Appendex_1_head_5}</p>
          <p className="content">{strings.Appendex_1_head_5_para_1}</p>
          <p className="content_sub_heading">{strings.Appendex_1_head_6}</p>
          <p className="content">{strings.Appendex_1_head_6_para_1}</p>{" "}
          <p className="content_sub_heading">{strings.Appendex_1_head_7}</p>
          <p className="content">{strings.Appendex_1_head_7}</p>{" "}
          <p className="content_sub_heading">{strings.Appendex_1_head_8}</p>
          <p className="content">{strings.Appendex_1_head_8_para_1}</p>
          <p className="content_sub_heading">{strings.Appendex_1_head_9}</p>
          <p className="content">{strings.Appendex_1_head_9_para_1}</p>
          <p className="content_sub_heading">{strings.Appendex_1_head_10}</p>
          <p className="content">{strings.Appendex_1_head_10_para_1}</p>
          <p className="content">{strings.Appendex_1_para_2}</p>
          <p className="content">{strings.Appendex_2}</p>
          <p className="content_sub_heading">
            {strings.Appendex_2_article1_head}
          </p>
          <p className="content">{strings.Appendex_2_article1_para_1}</p>
          <p className="content">{strings.Appendex_2_article1_para_2}</p>
          <p className="content">{strings.Appendex_2_article1_para_3}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Appendex_2_article2_head}</p>
          <p className="content_sub_heading">
            {strings.Appendex_2_article2_1_head}
          </p>
          <p className="content">{strings.Appendex_2_article2_1_para_1}</p>
          <p className="content">{strings.Appendex_2_article2_1_point_1}</p>
          <p className="content">{strings.Appendex_2_article2_1_point_2}</p>

          <p className="content_sub_heading">
            {strings.Appendex_2_article2_2_head}
          </p>

          <p className="content">{strings.Appendex_2_article2_2_para_1}</p>
          <p className="content">{strings.Appendex_2_article2_2_para_2}</p>
          <p className="content">{strings.Appendex_2_article2_2_para_3}</p>

          <p className="content_sub_heading">
            {strings.Appendex_2_article2_3_head}
          </p>
          <p className="content">{strings.Appendex_2_article2_3_para_1}</p>

          <p className="content_sub_heading">
            {strings.Appendex_2_article2_4_head}
          </p>
          <p className="content">{strings.Appendex_2_article2_4_para_1}</p>

          <p className="content_sub_heading">
            {strings.Appendex_2_article2_5_head}
          </p>
          <p className="content">{strings.Appendex_2_article2_5_para_1}</p>
          <p className="content">{strings.Appendex_2_article2_5_para_2}</p>

          <p className="content_sub_heading">
            {strings.Appendex_2_article2_6_head}
          </p>
          <p className="content">{strings.Appendex_2_article2_6_para_1}</p>
          <p className="content">{strings.Appendex_2_article2_6_para_2}</p>
          <p className="content">{strings.Appendex_2_article2_6_para_3}</p>
          <p className="content">{strings.Appendex_2_article2_6_para_4}</p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            // width: "900px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p className="content_heading">{strings.Appendex_1_head}</p>
          <p className="content_sub_heading">
            {strings.Appendex_3_article1_head}
          </p>
          <p className="content">{strings.Appendex_3_article1_para_1}</p>
          <p className="content">{strings.Appendex_3_article1_para_2}</p>
          <p className="content">{strings.Appendex_3_article1_para_3}</p>

          <p className="content_sub_heading">
            {strings.Appendex_3_article2_head}
          </p>

          <p className="content">{strings.Appendex_3_article2_para_1}</p>
          <p className="content">{strings.Appendex_3_article2_para_2}</p>

          <p className="content_sub_heading">
            {strings.Appendex_3_article3_head}
          </p>
          <p className="content">{strings.Appendex_3_article3_para_1}</p>
          <p className="content">{strings.Appendex_3_article3_para_2}</p>

          <p className="content_sub_heading">
            {strings.Appendex_3_article4_head}
          </p>
          <p className="content">{strings.Appendex_3_article4_para_1}</p>

          <p className="content_sub_heading">
            {strings.Appendex_3_article5_head}
          </p>
          <p className="content">{strings.Appendex_3_article5_para_1}</p>
          <p className="content">{strings.Appendex_3_article5_para_2}</p>

          <p className="content_sub_heading">
            {strings.Appendex_3_article6_head}
          </p>
          <p className="content">{strings.Appendex_3_article6_para_1}</p>
          <p className="content">{strings.Appendex_3_article6_para_2}</p>
        </Grid>
      </Grid>
      </div>
   
  );
}

export default TermsAndCondition;
