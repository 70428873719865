import React, { useState } from "react";
import "./header.css";
import { FrankIcon, MailOrangeIcon, UserOrangeIcon } from "../../assets/images/FrankIcons";
import { ArrowDropDown, Close, KeyboardArrowDown, Logout, Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Avatar, Divider, Drawer, IconButton, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { clearAllDataFromLocalStorage, getDataFromLocalStorage } from "../../utils/LocalStore.util";
import Toast from "../Toast/Toast";
import drawerBoxIcon from "../../assets/images/drawer-box-icon.png";
function NewHeader() 
{
  const [state, setState] = React.useReducer(
      (state: any, newState: any) => ({ ...state, ...newState }),
      {
      currentLanguage:"FR"
      }
    );

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = (open:any) => (event:any) => {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
      setIsDrawerOpen(open);
    };
  
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    let userDetails = getDataFromLocalStorage("user");
    const handleLogout = () => {
      try {
        clearAllDataFromLocalStorage();
        navigate("/");
        Toast.showSuccess("Logout Succesfully");
      } catch (error) {}
    };
  

    
return (
  <div
    style={{
      padding: 10,
      background: "white",
      borderRadius: 100,
      marginTop: 10,
      // width: "100%",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 14,
          width: "100%",
          // justifyContent: "space-between",
        }}
      >
         
         <a href="/" style={{ marginBottom: "-10px",marginLeft:25 }}>
            <FrankIcon fill={"#ee6931"} height={55} />
          </a>
        <div 
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 14,
            flex:1
          }}
        >
          <div className="font_resp">
          <div
              className="menu-component menu-container"
              style={{ cursor: "pointer" }}
            >
              <span
                className="particulier font_resp"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Particulier{" "}
                <KeyboardArrowDown
                  style={{ fontSize: "24px", fontWeight: "lighter" }}
                />
              </span>

              <div className="dropdown">
                <div
                  className="dropdown-item"
                  
                >
                  Envoyer un colis
                </div>
                <div className="dropdown-item">Suivre mon colis</div>
                <div className="dropdown-item">Devenir transporteur</div>
              </div>
            </div>

          </div>
          <div className="font_resp">
          <div
              className="menu-component menu-container"
              style={{ cursor: "pointer" }}
            >
              <span
                className="particulier font_resp"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Transporteur{" "}
                <KeyboardArrowDown
                  style={{ fontSize: "24px", fontWeight: "lighter" }}
                />{" "}
              </span>

              <div className="dropdown">
                <div className="dropdown-item">Devenir Transporteur</div>
                <div className="dropdown-item">
                  Voir les annonces 
                </div>
              </div>
            </div>

          </div>
          {/* <div className="font_resp">
          <div
              className="menu-component menu-container"
              style={{ cursor: "pointer" }}
            >
              <span
                className="particulier font_resp"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Déménagement{" "}
                <KeyboardArrowDown
                  style={{ fontSize: "24px", fontWeight: "lighter" }}
                />{" "}
              </span>

              <div className="dropdown">
                <div className="dropdown-item"> Déménageur particulier</div>
                <div className="dropdown-item">Déménageur professionnel</div>
              </div>
            </div>

          </div> */}
          <div className="font_resp">
          <div
              className="menu-component menu-container"
              style={{ cursor: "pointer" }}
            >
              <span
                className="particulier font_resp"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Market place
              </span>
            </div>

          </div>
          {/* <div className="font_resp">
          <div
              className="menu-component menu-container"
              style={{ cursor: "pointer" }}
            >
              <span
                className="particulier font_resp"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Stockage colis{" "}
                <KeyboardArrowDown
                  style={{ fontSize: "24px", fontWeight: "lighter" }}
                />
              </span>

              <div className="dropdown">
                <div className="dropdown-item">
                  {" "}
                  Trouver un point relais particulier
                </div>
                <div className="dropdown-item">
                  Proposer un espace de point relais
                </div>
                <div className="dropdown-item">Consigne bagage</div>
              </div>
            </div>
          </div> */}
          
        </div>
        <div className="hide_login_drawer" style={{cursor:'pointer',marginTop:'10px'}} onClick={toggleDrawer(true)}>
                    <svg
                      width="23px"
                      height="22px"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginTop: "-10px" }}
                    >
                      <path
                        d="M4 16H28M4 8H28M4 24H20"
                        stroke="#281208"
                        stroke-width="2.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
        </div>
        


    <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
                style={{
                  borderRadius: "0px",
                  background: "none",
                  height: "100vh",

                }}
                PaperProps={{
                  sx:{
                    width:'300px'
                  }
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, padding: "20px 0px 0px 20px" }}>
                    <FrankIcon fill={"#ee6931"} height={60} />
                  </div>
                  <div style={{ padding: "20px 20px 0px 0px" }}>
                    <IconButton onClick={toggleDrawer(false)}>
                      <Close style={{ fontSize: "28px", color: "#000" }} />
                    </IconButton>
                  </div>
                </div>
                <Typography variant="body1" style={{paddingLeft:'20px',flex:1,marginTop:'20px'}}>
        <div   style={{display:"flex",flexDirection:'column',gap:20}}>
          {userDetails ? (
            <div   style={{ cursor: "pointer",gap:20 }}>
              <div className="line-mail" style={{fontFamily:'Raleway-Variable',fontWeight:'600'}}>
                <MailOrangeIcon  /> <span style={{paddingLeft:'20px',fontFamily:'Raleway-Variable'}}> Email</span>
              </div>
            </div>
          ) : null}


          {userDetails ? (
            <div
              className="compte"
              style={{ cursor: "pointer", position: "relative" }}
            >
              <div className="user" style={{fontFamily:'Raleway-Variable',fontWeight:'600'}}>
                <div onClick={handleClick}>
                  <UserOrangeIcon /> <span style={{paddingLeft:'20px',fontFamily:'Raleway-Variable'}}> Account</span>
                </div>
              </div>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    width: "200px",
                    borderRadius: "12px",
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  className="dropdown-item"
                  style={{ fontFamily: "Raleway-Variable" }}
                  
                >
                  <Avatar /> Profil
                </MenuItem>

                <Divider />

                <MenuItem
                  className="dropdown-item"
                  style={{ fontFamily: "Raleway-Variable" }}
                  // onClick={handleClose}
                >
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem>
                <MenuItem
                  className="dropdown-item"
                  style={{ fontFamily: "Raleway-Variable" }}
                  // onClick={handleLogout}
                >
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <div style={{gap:'20px',marginTop:'20px'}} >
              <div
               
                style={{display:'flex',justifyContent:'flex-start',alignItems:'flex-start'}}

              >
                <span
                  className="particulier"
                  style={{
                    cursor: "pointer",
                    textAlign:'left'
                  }}
                >
                  S'inscrire
                </span>
              </div>

              <div
              
                
                style={{display:'flex',justifyContent:'flex-start',alignItems:'flex-start',marginTop:'20px'}}
              >
                <span
                  className="particulier  "
                  style={{
                    cursor: "pointer",
                    textAlign:'center'
                  
                  }}
                >
                  Se connecter
                </span>
              </div>
            </div>
          )}

          
          </div>
        </Typography>
        <Typography 
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <img src={drawerBoxIcon} height={"60%"} alt="" />
                </div>
                </Typography>
              </Drawer>
       
        <div className="login_area">
        <div className="right" style={{display:"flex",gap:'20px', justifyContent:'center',alignItems:'center'}}>
          {userDetails ? (
            <div className="tracker" style={{ cursor: "pointer" }}>
              <div className="line-mail">
                <MailOrangeIcon  />
              </div>
            </div>
          ) : null}


          {userDetails ? (
            <>
             

            <div
              className="compte"
              style={{ cursor: "pointer", position: "relative",display:"flex",justifyContent:'center',alignItems:'center' }}
            >
              <div className="user" style={{}}>
                <div >
                  <UserOrangeIcon />
                </div>
              </div>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    width: "200px",
                    borderRadius: "12px",
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  className="dropdown-item"
                  style={{ fontFamily: "Raleway-Variable" }}
                  
                >
                  <Avatar /> Profil
                </MenuItem>

                <Divider />

                <MenuItem
                  className="dropdown-item"
                  style={{ fontFamily: "Raleway-Variable" }}
                  // onClick={handleClose}
                >
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem>
                <MenuItem
                  className="dropdown-item"
                  style={{ fontFamily: "Raleway-Variable" }}
                  // onClick={handleLogout}
                >
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </div>
            </>
          ) : (
            <>
            <div className="font_resp"> <div
            className="menu-component menu-container"
            style={{ cursor: "pointer" }}
          >
          
          </div></div>
            <div style={{display:'flex',flexDirection:"row",justifyContent:'center',alignItems:'center',border:"1px solid #F4C7B8 ",borderRadius:4,padding:5,color:"#ee6931",gap:10}}>
            
           
              <div
                
                style={{display:'flex',justifyContent:'center',alignItems:'center'}}

              >
                <span
                  className="particulier font_resp"
                  style={{
                    cursor: "pointer",
                    textAlign:'center'
                  }}
                >
                  S'inscrire
                </span>
              </div>
<div style={{width:"2px",height:"10px",background:"#F4C7B8"}}>
</div>
              <div
                
                style={{display:'flex',justifyContent:'center',alignItems:'center'}}
              >
                <span
                  className="particulier font_resp"
                  style={{
                    cursor: "pointer",
                    textAlign:'center'
                  
                  }}
                >
                  Se connecter
                </span>
              </div>
            </div>
            </>
          )}

          <div
              className="compte"
              style={{ cursor: "pointer", position: "relative" }}
            >
              <div className="user">
                <div  className="particulier font_resp" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                  { "FR"} <ArrowDropDown />
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
}

export default NewHeader;
